var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "100%, width: 100%" }, attrs: { id: _vm.id } },
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "lg",
            title: _vm.title,
            "footer-class": "footerClass",
            "no-close-on-backdrop": "",
            "content-class": "shadow",
            "modal-class": [_vm.id],
          },
          on: { ok: _vm.ok, hidden: _vm.hidden },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var ok = ref.ok
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        disabled: _vm.disableOk,
                        size: "sm",
                        variant: "success",
                      },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.ok")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$i18n.t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.modalShow,
            callback: function ($$v) {
              _vm.modalShow = $$v
            },
            expression: "modalShow",
          },
        },
        [
          _c("ag-grid-vue", {
            staticClass: "ag-theme-balham selector-grid-height",
            staticStyle: { width: "100%" },
            attrs: {
              id: "stage-grid",
              gridOptions: _vm.gridOptions,
              columnDefs: _vm.columnDefs,
              context: _vm.context,
              defaultColDef: _vm.defaultColDef,
              getRowId: function (params) {
                return params.data.num
              },
              noRowsOverlayComponent: "noRowsOverlay",
              noRowsOverlayComponentParams: _vm.noRowsOverlayComponentParams,
              overlayLoadingTemplate: _vm.overlayLoadingTemplate,
              pagination: "",
              paginationPageSize: 1000,
              cacheBlockSize: 10000,
              rowData: _vm.rowData,
              rowMultiSelectWithClick: "",
              rowSelection: "multiple",
              sideBar: false,
              suppressDragLeaveHidesColumns: "",
              suppressCellFocus: "",
              suppressContextMenu: "",
              suppressMultiSort: "",
            },
            on: { "grid-ready": _vm.onGridReady },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }