<template>
  <div :id="id" style="height: 100%, width: 100%">
    <b-modal v-model="modalShow" size="lg" :title="title" footer-class="footerClass"
      no-close-on-backdrop  content-class="shadow" :modal-class="[id]"
      @ok="ok" @hidden="hidden">
      
      <ag-grid-vue style="width: 100%;" class="ag-theme-balham selector-grid-height" id="stage-grid"
            :gridOptions="gridOptions"
            @grid-ready="onGridReady"
            :columnDefs="columnDefs"
            :context="context"
            :defaultColDef="defaultColDef"
            :getRowId="params => params.data.num"
            noRowsOverlayComponent="noRowsOverlay"
            :noRowsOverlayComponentParams="noRowsOverlayComponentParams"
            :overlayLoadingTemplate="overlayLoadingTemplate"
            pagination
            :paginationPageSize="1000"
            :cacheBlockSize="10000"
            :rowData="rowData"
            rowMultiSelectWithClick
            rowSelection="multiple"
            :sideBar="false"
            suppressDragLeaveHidesColumns
            suppressCellFocus
            suppressContextMenu
            suppressMultiSort
            >
     </ag-grid-vue>

      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button :disabled="disableOk" size="sm" variant="success" @click="ok()">{{ $t('button.ok') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $i18n.t('button.cancel') }}</b-button>
      </template>
    </b-modal>
 
  </div>
</template>

<script>
import 'ag-grid-enterprise';
import { AgGridVue } from 'ag-grid-vue';
import { strRandom, objectClone } from '@/helpers';

import currencies from '@/views/management/script/currencies';

import DetailLinkCellRenderer from '@/components/Aggrid/CellRenderer/DetailLink';
import NoRowsOverlay from '@/components/Aggrid/Overlay/NoRows';

export default {
  name: 'EnumCurrencySelectorModal',
  components: {
    'ag-grid-vue': AgGridVue
    
    //aggrid cell renderer/editor/header component
    /* eslint-disable vue/no-unused-components */
    , 'detailLinkCellRenderer': DetailLinkCellRenderer
    //Overlay
    , noRowsOverlay: NoRowsOverlay
    /* eslint-enable vue/no-unused-components */
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    preselected: {
      type: Array,
      default: () => []
    }
  },
  data: function() {
    return {
      id: `CURRENCY_LIST_${strRandom(5)}`,
      
      modalShow: false,
      gridOptions: null,
      columnDefs: null,
      context: null,
      defaultColDef: null,
      rowData: null,
      
      disableOk: true,

      selected: [],
      
      lastOpenColumnMenuParams: null,
      noRowsMessage: null,
      noRowsOverlayComponentParams: null
    };
  },
  beforeMount() {
    const self = this;
    this.gridOptions = {
      onSelectionChanged: function(event) {
        self.disableOk = event.api.getSelectedNodes().length < 1;
      },
      
      onFirstDataRendered: function(event) {
        event.api.sizeColumnsToFit()
      }
    };
    this.columnDefs = [
      {
        headerName: this.$t('name'),
        field: 'name',
        checkboxSelection: true,
        lockVisible: true,
        pinned: 'left',
        minWidth: 200,
        hide: false
      },
      {
        headerName: this.$t('value'),
        field: 'code',
        hide: false
      }
    ];

    this.defaultColDef = {
      sortable: false,
      resizable: true,
      minWidth: 100,
      lockPinned: true,
      hide: true,
      menuTabs: ['columnsMenuTab']
    };
    
    this.context = {
      componentParent: self
    };
  },
  mounted() {
  },
  created() {
    this.noRowsOverlayComponentParams = {
      msgFunc: this.prepareNoRowsMessage
    }
    this.updateModalShow(this.show);
    if (this.show) {
      this.prepareData()
    }
  },
  beforeDestroy() {
    
  },
  watch: {
    async show(newValue) {
      if(newValue) {
        this.prepareData()
      }
      this.updateModalShow(newValue);
    }
  },
  computed: {
    title() {
        return this.$t('enum.currency_selector_title')
    }
    , overlayLoadingTemplate() {
      return `<span class='grid-overlay'><div class="mr-1 spinner-grow spinner-grow-sm text-dark"></div>${ this.$t('enum.grid_loading') }</span>`;
    }
  },
  methods: {
    onGridReady(/**params*/) {
      
    },
    updateModalShow(newValue) {
      this.modalShow = newValue;
    },
    ok() {
      const details = this.gridOptions.api.getSelectedNodes().map(i => { return {
        num: i.data.num, name: i.data.name, code: i.data.code
      } });

      this.$emit('ok', { selectedList: details });
    },
    hidden() {
      this.alert = null;
      this.selected.splice(0, this.selected.length);
      this.$emit('update:show', false);
      this.$emit('cancel');
    },
    prepareData() {
      const list = objectClone(currencies)
      if (this.preselected.length > 0) {
        const preselected = objectClone(this.preselected)
        do {
          const cur = preselected.shift()
          const index = list.findIndex(i => i.code == cur.code)
          if (index > -1) {
            list.splice(index, 1)
          }
        } while(preselected.length > 0)
      }
      
      list.sort(function(a, b) {
        if ((a == null || a.name == null) && (b == null || b.name == null)) {
          return 0;
        } else if (a == null || a.name == null || a.name < b.name) {
          return -1;
        } else if (b == null || b.name == null || a.name > b.name) {
          return 1;
        }
        return 0;
      })
      
      this.rowData = list
    },
    prepareNoRowsMessage() {
      if (this.noRowsMessage != null) {
        return this.noRowsMessage
      }
      return this.$t(`grid.no_data`)
    },
    showNoRowLabel(msg=null) {
      this.noRowsMessage = msg
      let api = this.gridOptions != null? this.gridOptions.api : null
      if (api != null) {
        this.gridOptions.api.hideOverlay()
        setTimeout(() => {
          api.showNoRowsOverlay()
        })
      }
    }
  }
}


</script>